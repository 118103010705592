import get_title from "@/router/module/get_title";
import NomenclatureModelRouterHook from "@/new/auth/nomenclature-model.router-hook";

export default {
  path: "/content/nomenclature/:productId/models",
  name: "product-edit__models",
  meta: {
    title: get_title("Товар | Модели"),
    forVisitors: true,
    requiresAuth: true
  },
  component: () => import("@/new/pages/nomenclature/edit/models.vue"),
  beforeEnter: NomenclatureModelRouterHook("product.show-models"),
};
