import { defineStore } from "pinia";
import { SendingImageCancel } from "@/axios/product/image/sending-cancel";
import { handleErrorArray, handleResponseMessageArray } from "@/axios/response-handler";

export enum SendingStatus {
  IN_PROCESS = "processing",
  FINISHED = "successful finished",
  CANCELED = "canceled",
  AVAILABLE = "available",
}
export interface SendingProcessState {
  status: SendingStatus;
  progressValue?: number;
}

export const useProductMediaStore = defineStore("ProductMediaStore", {
  state: (): SendingProcessState => ({
    status: SendingStatus.AVAILABLE,
    progressValue: undefined,
  }),
  getters: {
    progress: (state) => state.progressValue ?? 0,
    process_exists: (state) => state.status !== SendingStatus.AVAILABLE,
    is_sending: (state) => state.status === SendingStatus.IN_PROCESS,
    is_sending_canceled: (state) => state.status === SendingStatus.CANCELED
  },
  actions: {
    pushStep(progress) {
      this.progressValue = progress;
    },
    finish() {
      this.progressValue = 100;
      this.status = SendingStatus.FINISHED;
    },
    start() {
      this.status = SendingStatus.IN_PROCESS;
      this.progressValue = 0;
    },
    cancel() {
      this.status = SendingStatus.CANCELED;
    },
    async requestCancel() {
      try {
        const response = await SendingImageCancel();
        handleResponseMessageArray(response);
      } catch (e) {
        handleErrorArray(e);
      }
    }
  },
});
