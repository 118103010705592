import get_title from "@/router/module/get_title";
import NomenclatureModelRouterHook from "@/new/auth/nomenclature-model.router-hook";

export default {
  path: "/content/catalog/:modelId/images",
  name: "model-edit__images",
  meta: {
    title: get_title("Модель | Изображения"),
    forVisitors: true,
    requiresAuth: true
  },
  component: () => import("@/components/dialogs/catalog/model-edit-images/index.vue"),
  beforeEnter: NomenclatureModelRouterHook("model.show-images"),
};
