import get_title from "@/router/module/get_title";
import validate_role from "@/router/module/validate_role";

export default {
  path: "/content/settings/attribute/create",
  name: "attribute.create",
  component: () => import("@/new/pages/settings/attribute/detail/create.vue"),
  meta: {
    title: get_title("Добавление поля"),
    forVisitors: true,
    requiresAuth: true
  },
  beforeEnter: (to, from, next) => {
    const options = {
      role: "settings_attribute",
      route: { to, from, next },
      callback: false,
      redirectText: "У вас нет доступа к разделу с настройками!",
    };
    validate_role(options);
  },
};
