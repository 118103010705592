import axios from "@/axios";
import realtime, { connect } from "@/realtime";
import store from "@/store";
import { ProductImportProcess } from "./product-import-process";
import { ProductSendMediaProcess } from "./product-send-media-process";
import { useFilterBus } from "@/new/shared/filters";
import { useReferenceValueList } from "@/new/modules/reference-value/store/reference-value-list";

export default async () => {
  try {
    const referenceListStore = useReferenceValueList();
    const filterDataBus = useFilterBus();
    const { data } = await axios("/web-socket/get-token");
    connect(process.env.VUE_APP_WEBSOCKET_URL, data.result.token, [
      "drafts",
      "references",
      `USER_CHANNEL_${store.getters["auth/profile"]["id"]}`,
    ]);

    realtime.setListener("DRAFTS_CURRENT_DRAFT_CHANGE", () => {
      store.dispatch("changes/fetch");
      store.dispatch("changes/setHistoryList");
    });
    realtime.setListener("FILTER", (data) => {
      if (data && data["message"]) {
        filterDataBus.emit(data["message"]);
      }
    });
    realtime.setListener("REFERENCES_CHANGE_EVENT", (data) => {
      referenceListStore.initSubscription()
      if (data?.message?.code_reference) {
        referenceListStore.setChangeReference(data.message.code_reference)
      }
    });
    ProductImportProcess();
    ProductSendMediaProcess();
  } catch (e) {
    console.error(e);
  }
};
