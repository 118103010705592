import get_title from "@/router/module/get_title";
import validate_role from "@/router/module/validate_role";

export default {
  path: "/content/settings/reference/:id/edit",
  alias: "/content/settings/reference/edit/:id",
  name: "reference.edit",
  component: () => import("@/new/pages/settings/reference/detail/edit.vue"),
  meta: {
    title: get_title("Редактирование справочника"),
    forVisitors: true,
    requiresAuth: true
  },
  beforeEnter: (to, from, next) => {
    const options = {
      role: "settings_reference",
      route: { to, from, next },
      callback: false,
      redirectText: "У вас нет доступа к разделу с настройками!",
    };
    validate_role(options);
  },
};
