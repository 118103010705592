import get_title from "@/router/module/get_title";
import NomenclatureModelRouterHook from "@/new/auth/nomenclature-model.router-hook";

export default {
  path: "/content/nomenclature/new",
  name: "product__new",
  meta: {
    title: get_title("Товар | Новый"),
    forVisitors: true,
    requiresAuth: true
  },
  component: () => import("@/new/pages/nomenclature/new/index.vue"),
  beforeEnter: NomenclatureModelRouterHook("product.show-general"),
};
