import backurl from "@/router/module/backurl";
import get_title from "@/router/module/get_title";
import validate_role from "@/router/module/validate_role";

export default {
  path: "/restore",
  name: "RestorePassword",
  component: () => import("@/new/pages/restore.vue"),
  meta: {
    title: get_title("Восстановление пароля"),
    forVisitors: true,
    requiresAuth: false
  },
  beforeEnter: (to, from, next) => {
    const options = {
      route: { to, from, next },
      variant: "login",
      redirectText:
        "Вы уже авторизованы. Если вы хотите восстановить пароль, сначала выйдите из текущего аккаунта",
    };
    validate_role(options);
    if (router.history._startLocation === "/restore") {
      backurl(options, "/content/nomenclature");
    } else {
      backurl(options, router.history._startLocation);
    }
  }
};
