import realtime from "@/realtime";
import { NotificationService } from "@/new/utils/notification.service";
import { useProductMediaStore } from "@/new/modules/product/store/product-media.store";
import { ErrorService } from "@/new/utils/error.service";

enum SendingSocketEvents {
  start = "SENDMEDIA_STARTS",
  update = "SENDMEDIA_UPDATE",
  error = "SENDMEDIA_ERROR",
  interupt = "SENDMEDIA_INTERRUPT",
  finish = "SENDMEDIA_READY",
}

export const ProductSendMediaProcess = () => {
  const store = useProductMediaStore();
  realtime.setListener(SendingSocketEvents.start, ({ message }) => {
    NotificationService(message);
    store.start();
  });
  realtime.setListener(
    SendingSocketEvents.update,
    (_placeholder, message) => {
      store.pushStep(message?.progress);
    }
  );
  realtime.setListener(SendingSocketEvents.interupt, ({ message }) => {
    ErrorService(message);
    store.cancel();
  });
  realtime.setListener(SendingSocketEvents.error, ({ message }) => {
    ErrorService(message);
    store.cancel();
  });
  realtime.setListener(SendingSocketEvents.finish, ({ message }) => {
    NotificationService(message);
    store.finish();
  });
};
