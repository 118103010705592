import get_title from "@/router/module/get_title";
import ProductEdit from "./edit";
import NewProduct from "./new";

export default [
  {
    path: "/content/nomenclature",
    name: "product__listing",
    component: () => import("@/new/pages/nomenclature"),
    meta: {
      title: get_title("Номенклатура"),
      forVisitors: true,
      requiresAuth: true
    },
    children: [NewProduct],
  },
  ProductEdit,
];
