import get_title from "@/router/module/get_title";
import { MatrixOperationsRouterHook } from "@/new/auth/matrix-operations.router-hook";

export default {
  path: "/content/matrices/:id/accessSettings",
  alias: "/content/matrices/accessSettings/:id",
  name: "matrices.access-setting",
  component: () => import("@/new/pages/matrices/detail/access-settings.vue"),
  meta: {
    title: get_title("Настройки доступа"),
    forVisitors: true,
    requiresAuth: true
  },
  beforeEnter: MatrixOperationsRouterHook,
};
