import axios, { RESPONSE_CODE } from "@/axios";
import { ReferenceValues } from "@/types"

export async function ReferenceValueListGet(
  referenceName: string,
  search = ""
): Promise<Array<ReferenceValues>> {
  const { data } = await axios.get(`/reference/${referenceName}/value/list`, {
    params: { search },
  });
  let result = undefined;
  if (data && data["code"] === RESPONSE_CODE.OK && data["result"]) {
    result = data["result"];
  }

  return result;
}
