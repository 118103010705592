import get_title from "@/router/module/get_title";
import validate_role from "@/router/module/validate_role";
import createAttribute from "./detail/create";
import editAttribute from "./detail/edit";

export default {
  path: "/content/settings/attribute",
  name: "Settings-Attribute",
  component: () => import("@/new/pages/settings/attribute/index.vue"),
  meta: {
    title: get_title("Настройки полей"),
    forVisitors: true,
    requiresAuth: true
  },
  beforeEnter: (to, from, next) => {
    const options = {
      role: "settings_attribute",
      route: { to, from, next },
      callback: false,
      redirectText: "У вас нет доступа к разделу с настройками!",
    };
    validate_role(options);
  },
  children: [createAttribute, editAttribute],
};
