import { defineStore } from "pinia";
import { ReferenceValueListGet } from "@/axios/reference-value/reference-value-list.get";
import { ReferenceValues } from "@/types";

interface LoadingObject {
  [key: string]: boolean;
}
interface ReferenceValuesObject {
  [key: string]: Array<ReferenceValues>;
}

export interface ReferenceValueListStore {
  referenceObject: ReferenceValuesObject;
  changeReference: ReferenceValuesObject;
  loadingObject: LoadingObject;
  searchQuery?: string;
}
export const useReferenceValueList = defineStore("ReferenceValueListStore", {
  state: (): ReferenceValueListStore => ({
    referenceObject: {},
    changeReference: {},
    searchQuery: undefined,
    loadingObject: {},
  }),
  getters: {
    getReferenceList: (state) => {
      return (referenceName: string) => state.referenceObject[referenceName] || [];
    },
    getReferenceByID: (state) => {
      return (referenceName: string, id: number) => state.referenceObject[referenceName]?.find(item => item.id === id) || {};
    },
    isLoadingObject: (state) => {
      return (referenceName: string) => state.loadingObject[referenceName] || false;
    }
  },
  actions: {
    async SrcLoad(referenceName: string) {
      if (!this.referenceObject[referenceName]) {
        this.commitLoading(referenceName)
      }
      if (!this.referenceObject[referenceName] || this.changeReference[referenceName]) {
        const list = await ReferenceValueListGet(referenceName)
        if (list) {
          this.$patch({
            referenceObject: {
              [referenceName]: list
            }
          })
        }
        this.commitLoaded(referenceName)
        if (this.changeReference[referenceName]) {
          delete this.changeReference[referenceName]
        }
      }
    },
    commitLoading(referenceName) {
      this.$patch({
        loadingObject: {
          [referenceName]: true
        }
      })
    },
    commitLoaded(referenceName) {
      this.$patch({
        loadingObject: {
          [referenceName]: false
        }
      })
    },
    cleanupSearchQuery() {
      this.searchQuery = undefined;
    },
    updateList(referenceName: string, selectedIDs: Array<number>) {
      let reorderedList = this.referenceObject[referenceName].sort((a, b) => {
        const aIsSelected = selectedIDs.includes(a.id);
        const bIsSelected = selectedIDs.includes(b.id);
        if (aIsSelected === bIsSelected) {
          return 0;
        }
        if (aIsSelected) {
          return -1;
        }
        return 1;
      });

      this.$patch({
        referenceObject: {
          [referenceName]: reorderedList
        }
      })
    },
    setChangeReference(referenceName: string) {
      this.$patch({
        changeReference: {
          [referenceName]: true
        }
      })
    },
    initSubscription() {
      this.$subscribe((mutation, state) => {
        if (mutation.type === "patch object" && mutation?.payload?.changeReference) {
          for (const referenceName in state.changeReference) {
            if (state.changeReference.hasOwnProperty(referenceName)) {
              this.SrcLoad(referenceName)
            }
          }
        }
      })
    }
  }
});