import NomenclatureModelRouterHook from "@/new/auth/nomenclature-model.router-hook";
import get_title from "@/router/module/get_title";

export default {
  path: "/content/nomenclature/:productId/settings",
  name: "product-edit__settings",
  meta: {
    title: get_title("Товар | Основная информация"),
    forVisitors: true,
    requiresAuth: true
  },
  component: () => import("@/new/pages/nomenclature/edit/settings.vue"),
  beforeEnter: NomenclatureModelRouterHook("product.show-general"),
};
