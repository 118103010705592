import { CategoryListGet } from "@/axios/category/category-list.get";
import { CategoryListPut } from "@/axios/category/category-list.put";

const state = () => ({
  loaded: false,
  isLoading: false,
  initialCategory: {},
  categoryByProject: {},
});
const mutations = {
  Loaded(state, status) {
    state.loaded = status;
  },
  IsLoading(state, status) {
    state.isLoading = status;
  },
  InitialCategory(state, data) {
    state.initialCategory = data
  },
  CategoryByProject(state, data) {
    state.categoryByProject = data
  }
};

const actions = {
  async fetch({ state, commit }) {
    if (!state.isLoading) {
      commit("IsLoading", true);
      const data = await CategoryListGet();
      if (data) {
        for (const project of data) {
          commit("CategoryByProject", data);
          commit("InitialCategory", JSON.parse(JSON.stringify(data)))
        }
        commit("Loaded", true);
      }
      commit("IsLoading", false);
    }
  },
  async update({ dispatch }, data) {
    await CategoryListPut(data.platform, data.list);
    return dispatch("fetch");
  },
  async delete({ commit }) {
    commit("CategoryByProject", {});
    commit("Loaded", false);
  }
};

const getters = {
  categories: (state) => {
    return { ...state };
  },
  loaded: (state) => state.loaded,
  categoryByProject: (state) => state.categoryByProject,
  isEqual: (state) => {
    return JSON.stringify(state.initialCategory) === JSON.stringify(state.categoryByProject)
  }
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
