import get_title from "@/router/module/get_title";
import NomenclatureModelRouterHook from "@/new/auth/nomenclature-model.router-hook";

export default {
  path: "/content/catalog/:modelId/categories",
  name: "model-edit__categories",
  meta: {
    title: get_title("Модель | Категории"),
    forVisitors: true,
    requiresAuth: true
  },
  component: () => import("@/components/dialogs/catalog/model-edit-categories.vue"),
  beforeEnter: NomenclatureModelRouterHook("model.show-categories"),
};
