import "normalize.css";
import "@/assets/Fonts/Lato/lato.css";
import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import PortalVue from "portal-vue";
import i18n from "@/new/plugins/i18n";
import vuetify from "@/new/plugins/vuetify";
import { pinia } from "@/new/plugins/pinia";
import { userSentryInstall } from "@/new/plugins/user-sentry-install";
import { migratePersistedStorage } from "@/new/plugins/user-config-migrate";
import axios from "./axios";
import router from "./router";
import store from "./store";
import sentry from "./sentry";
import App from "./App.vue";

// инициализация Sentry
sentry(Vue, router);
router.beforeEach((to, from, next) => {
  document.title = to.meta["title"];
  next();
});

const version = "1.2.0";
migratePersistedStorage(version);
userSentryInstall();

Vue.use(PortalVue);

Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
const app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  pinia,
  render: (h) => h(App),
});
app.$mount("#app");
