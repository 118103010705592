import get_title from "@/router/module/get_title";
import NomenclatureModelRouterHook from "@/new/auth/nomenclature-model.router-hook";

export default {
  path: "/content/catalog/new",
  name: "model__new",
  meta: {
    title: get_title("Модель | Новая"),
    forVisitors: true,
    requiresAuth: true
  },
  component: () => import("@/components/dialogs/catalog/model-edit-settings.vue"),
  beforeEnter: NomenclatureModelRouterHook("model.show-general"),
};
