import newRouters from "@/new/router";
import { MainRouterHook } from "@/new/auth/main.router-hook";
import get_title from "@/router/module/get_title";

import Catalog from "./catalog";
import Categories from "./Categories";
import History from "./History";

export default {
  path: "/",
  name: "Main",
  redirect: {
    path: "/content/nomenclature",
  },
  meta: {
    title: get_title(process.env.VUE_APP_TITLE),
    forVisitors: true,
    requiresAuth: true
  },
  component: () => import("@/views/Content"),
  children: [...Catalog, Categories, History, ...newRouters],
  beforeEnter: MainRouterHook,
};
