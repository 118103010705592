import get_title from "@/router/module/get_title";
import validate_role from "@/router/module/validate_role";

export default {
  path: "/content/settings/language/:id/edit",
  alias: "/content/settings/language/edit/:id",
  name: "language.edit",
  component: () => import("@/new/pages/settings/language/detail/edit.vue"),
  meta: {
    title: get_title("Редактирование языка"),
    forVisitors: true,
    requiresAuth: true
  },
  beforeEnter: (to, from, next) => {
    const options = {
      role: "settings_language",
      route: { to, from, next },
      callback: false,
      redirectText: "У вас нет доступа к разделу с настройками!",
    };
    validate_role(options);
  },
};
