import get_title from "@/router/module/get_title";
import { MatrixOperationsRouterHook } from "@/new/auth/matrix-operations.router-hook";

export default {
  path: "/content/matrices/:id/edit",
  alias: "/content/matrices/edit/:id",
  name: "matrices.edit",
  component: () => import("@/new/pages/matrices/detail/edit.vue"),
  meta: {
    title: get_title("Редактирование матрицы"),
    forVisitors: true,
    requiresAuth: true
  },
  beforeEnter: MatrixOperationsRouterHook,
};
