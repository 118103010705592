import axios, {RESPONSE_CODE} from "@/axios";
import {RestoreDto, ResponseMessage, UserToken} from "@/types";
import {setRefreshToken, setToken} from "@/axios/local-storage";

type LoginResponse = ResponseMessage<
  | {
  accessToken: UserToken;
  refreshToken: UserToken;
}
  | undefined
  >;
export async function ResetPassPost(dto: RestoreDto) {
  let result = undefined
  const { data } = await axios.post<LoginResponse>("/auth/reset-password", dto);
  if (data.code === RESPONSE_CODE.OK && data.message === "success") {
    if (
      !!data.result &&
      !!data.result.accessToken &&
      !!data.result.refreshToken
    ) {
      setToken(data.result.accessToken.token);
      setRefreshToken(data.result.refreshToken.token);
      result = data;
    } else {
      result = data;
      setToken(undefined);
      setRefreshToken(data.result.refreshToken.token);
      delete axios.defaults.headers.common["Authorization"];
    }
  } else {
    result = data;
  }
  return result;
}
