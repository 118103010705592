import get_title from "@/router/module/get_title";
import catalog from "./models";
import settings from "./settings";
import categories from "./categories";
import price from "./price";
import images from "./images";
import Store from "@/store";

export default {
  path: "/content/nomenclature/:productId",
  name: "product-edit--spa",
  redirect: (to) => {
    const role = Store.getters["auth/role"];
    if (role.slug === "photographer") {
      return `/content/nomenclature/${to.params.productId}/images`;
    }
    return `/content/nomenclature/${to.params.productId}/settings`;
  },
  meta: {
    title: get_title("Товар"),
    forVisitors: true,
    requiresAuth: true
  },
  beforeEnter: (to, from, next) => {
    if (to && to.query && to.query.idProduct) {
      return next(`/content/nomenclature/${to.query.idProduct}/settings`);
    }
    return next();
  },
  component: () => import("@/new/pages/nomenclature"),
  children: [catalog, settings, categories, price, images],
};
